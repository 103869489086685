<template>
  <div class="nore-continer">
      <div class="nore-head">
                <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
    align="center"
      prop="date"
      label="日期"
      width="180">
    </el-table-column>
    <el-table-column
      prop="name"
          align="center"
      label="姓名"
      width="280">
    </el-table-column>
     <el-table-column
     align="center"
      fixed="right"
      label="操作">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑地区</el-button>
        <el-button type="text" size="small">编辑原因</el-button>
         <el-button type="text" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="选择地区" :visible.sync="dialogFormVisible">
      <div class="area">
            <div class="area-a" v-for="item in prenvin" :key="item.id">
                <el-checkbox  v-model="checked">{{item.province}}</el-checkbox>
            </div>
      </div>
</el-dialog>

      </div>
  </div>
</template>

<script>
import {GetProvince } from '../../api/nore.js'
   export default {
     data() {
      return {
        tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],
        dialogFormVisible:true,//编辑地区
        prenvin:[],//地区列表,
      checked:false,
      }
    },
    methods:{
      //编辑地区
      handleClick(val){
          this.dialogFormVisible=true
      },
    //获取地区列表
    getvince(){
      GetProvince().then(res=>{
        console.log(res)
        if(res.code=10000){
          this.prenvin=res.data
        }
      }).catch(err=>{
         console.log(err)
      })
    }
    },
    created(){
      this.getvince()
    }
  }
</script>

<style  scoped>
.nore-head{
  width: 80%;
  margin:30px auto;
}
.area{
  width: 100%;
  height: 400px;
}
.area-a{
  /* width: 20%; */
  float: left;
  margin-bottom: 30px;
  margin-right: 40px;
}
</style>